import React from 'react'
import { map } from 'lodash'
import { staffs } from '../clients/iron-fit'

export const StaffName: React.FC<{ staffId: string }> = ({ staffId }) => {
  const [staff] = staffs.useStaff(staffId)
  const title = React.useMemo(() => map(staff?.corporationIds, (v, id) => id).join(', '), [staff])
  if (!staff) return null
  return <span title={title}>{staff.name}</span>
}
