import IronFit from '@irontrainers/live-assist'
import sha256 from 'sha256'
export * from '@irontrainers/live-assist/react'

const accountEmail = 'iron-live-support@staff.iron.fit'
const accountPassword = process.env.REACT_APP_IRON_FIT_STAFF_API_KEY
const useLocalApi = !!process.env.REACT_APP_IRON_FIT_USE_LOCAL_API
if (!accountEmail || !accountPassword) throw new Error('Cannot initialize Iron Fit connection!')

const ironFit = IronFit.initialize(accountEmail, accountPassword, { useLocalApi })

export default ironFit

const [authMode, authCorporationId] = getAuthTokenPayload()

export const mainCorporationId = authCorporationId || getMainCorporationId()
export const adminFlag = authMode === 'admin'
export const devFlag = authMode === 'dev'
export const customerFlag = authMode === 'customer'
export const bemcassiFlag = authCorporationId === 'bemcassi'
export const isAuthenticated = !!authMode

function getMainCorporationId(): string | undefined {
  const { /* hostname, */ search } = window.location
  const queryParams = new URLSearchParams(search)
  return queryParams.get('mainCorporationId') || undefined
}

function getAuthTokenPayload(): ['admin' | 'dev' | 'customer' | undefined, string | undefined] {
  const { search } = window.location
  const queryParams = new URLSearchParams(search)
  const authToken = queryParams.get('authToken')
  if (!authToken) return [undefined, undefined]
  const hashAuthToken = sha256(authToken)
  if (hashAuthToken === '8f61210dab0c73c1547e50f28ec9f2e93da16b2885a7aeff6c84df0d098852c6') return ['customer', 'bemcassi']
  // if (hashAuthToken === 'b7ae3d73b8dddcb279e54d85d125e1f28c532489bca412960e5325080a3f212a') return ['admin', undefined]
  if (hashAuthToken === '0cbace157404c970dde758ec8ab299ff9cd02a4158d8d8cbf6f1b9e2973819b1') return ['admin', undefined]
  if (hashAuthToken === '6649d0731483ecdef053efb5ffb5d3e72be9608e911032dca7deeabef319052d') return ['dev', undefined]
  if (hashAuthToken === 'b4148be74448b1dc71eef305318de31f88bdd1062cf33fda04a161f74c0dca5d') return ['customer', 'cassi']
  if (hashAuthToken === 'fca4aae55aee15aa090aca65b94a5ae4dbb7a882e5b49fc84e6f48bebfe6d97d') return ['customer', 'siriolibanes']
  return [undefined, undefined]
}
