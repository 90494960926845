import React from 'react'
import moment from 'moment'
import { LiveSupport } from '@irontrainers/live-assist/lib/rtdb'

import { emojis } from '../../../assets'
const { LIVE_EMOJI, FAILED_EMOJI, FINISHED_EMOJI } = emojis

export const LiveSupportWithSyncPendingItem: React.FC<{ supportId: string; support: LiveSupport }> = ({ supportId, support }) => {
  return (
    <div style={{ marginBottom: 10 }}>
      <p>
        {moment(support.startTime).format('DD/MM HH:mm')}
        {': '}
        {support.status === 'IN_PROGRESS' ? LIVE_EMOJI : support.status === 'FAILED' ? FAILED_EMOJI : FINISHED_EMOJI} {supportId}{' '}
        {support.syncErrorMessage}
      </p>
    </div>
  )
}
