/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/img-redundant-alt */
import React, { useState, useReducer } from 'react'
import Modal from 'react-modal'
import { Switch, Route } from 'react-router-dom'
import { isAuthenticated, adminFlag, devFlag, bemcassiFlag } from './clients/iron-fit'

import { LiveDebugPage, QueueMonitorPage } from './pages'

const USERNAME = 'bemcassi@iron.fit'
const PASSWORD = 'bc2021iron'

const getCookie = (cookieName: any) => {
  const cookieValues = document.cookie.split(';')
  for (let i = 0; i < cookieValues.length; i++) {
    let cookieValue = cookieValues[i]
    while (cookieValue.charAt(0) === ' ') {
      cookieValue = cookieValue.substring(1)
    }
    if (cookieValue.indexOf(cookieName) === 0) {
      return cookieValue.substring(cookieName.length + 1, cookieValue.length)
    }
  }
}

const setCookie = ({ userName, password }: any) => {
  const date = new Date()
  date.setTime(date.getTime() + 2 * 60 * 60 * 1000)
  const expires = '; expires=' + date.toUTCString()
  document.cookie = 'authentication=' + btoa(`${userName}:${password}`) + expires + '; path=/'
}

const credentials = getCookie('authentication')

let isBemcassiAuth = credentials === 'YmVtY2Fzc2lAaXJvbi5maXQ6YmMyMDIxaXJvbg=='

export const App: React.FC = () => {
  const [isBemcassi, setIsBemcassi] = useState(bemcassiFlag)
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  const userAuthentication = (userName: any, password: any) => {
    if (userName === USERNAME && password === PASSWORD) {
      isBemcassiAuth = true
      setIsBemcassi(true)
      setCookie({ userName, password })
    } else {
      isBemcassiAuth = false
      setIsBemcassi(false)
    }
    forceUpdate()
  }

  if (isAuthenticated && bemcassiFlag && isBemcassi && !isBemcassiAuth) {
    return (
      <Modal isOpen={isBemcassi && !isBemcassiAuth} style={customStyles}>
        <form className="authentication-form d-flex flex-column align-items-center">
          <input type="text" className="form-control" autoFocus placeholder="Enter username" onKeyUp={(e: any) => setUserName(e.target.value)} />
          <br />
          <input type="password"  className="form-control" placeholder="Enter password" onKeyUp={(e: any) => setPassword(e.target.value)} />
          <br />
          <button type="button"  className="btn btn-primary" onClick={() => userAuthentication(userName, password)}>
            OK
          </button>
        </form>
      </Modal>
    )
  }

  return (
    <div className="container-fluid container-application">
      <div className="main-content position-relative">
        {!isAuthenticated || (bemcassiFlag && !isBemcassi && !isBemcassiAuth) ? (
          <div>
            <h1>OPS, YOU'RE NOT AUTHORIZED TO ACCESS THE LIVE MONITOR!</h1>
            <h3>PLEASE, CONTACT THE ADMINISTRATORS.</h3>
          </div>
        ) : (
          <Switch>
            {(adminFlag || devFlag) && (
              <Route path="/live-debug">
                <LiveDebugPage />
              </Route>
            )}
            <Route path="/">
              <QueueMonitorPage />
            </Route>
          </Switch>
        )}
      </div>
    </div>
  )
}
