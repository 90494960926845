/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import moment from 'moment'
import { padStart } from 'lodash'

export function useTimeIntervalTicker(ms: number) {
  const [nowTicker, setNowTicker] = React.useState(Date.now())
  React.useEffect(() => {
    const nowTickerIntervalId = setInterval(() => {
      setNowTicker(Date.now())
    }, ms)
    return () => clearInterval(nowTickerIntervalId)
  }, [setNowTicker, ms])
  return nowTicker
}

export const Timer: React.FC<{ startTime: number }> = ({ startTime }) => {
  const nowTicker = useTimeIntervalTicker(1000)

  const duration = React.useMemo(() => {
    const diff = moment().diff(startTime)
    const duration = moment.duration(diff < 0 ? diff * -1 : diff)
    const counterParts: string[] = []

    const signal = diff < 0 ? '-' : ''

    if (duration.weeks()) counterParts.push(duration.weeks() + 's')
    if (duration.years()) counterParts.push(duration.years() + 'a')
    if (duration.months()) counterParts.push(duration.months() + 'm')
    if (duration.days()) counterParts.push(duration.days() + 'd')

    let hours: string = ''
    if (counterParts.length > 0 || duration.hours()) hours += padStart(duration.hours().toString(), 2, '0') + ':'
    hours += padStart(duration.minutes().toString(), 2, '0') + ':'
    hours += padStart(duration.seconds().toString(), 2, '0')
    counterParts.push(hours)

    return signal + counterParts.join(' ')
  }, [startTime, nowTicker])

  return (<>{duration}</>)
}
