/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { adminFlag, devFlag, mainCorporationId } from '../clients/iron-fit'
// import { setTimeout } from 'timers'

export const PageTitle: React.FC<{
  title: string
  noteStats?: number | string
  noteDescription?: string
  enableCorpsFilter?: boolean
  setPatientStatusData?: any
  setIsRefresh?: any
  currentQueue?: any
  currentStatus?: any
  isDebug?:any
}> = React.memo(({ title, noteStats, noteDescription, enableCorpsFilter, setPatientStatusData, setIsRefresh, currentQueue,
  currentStatus, isDebug }) => {
  const [corporations, setCorporations] = React.useState<any>()
  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_IRON_FIT_CORPORATION}doctor/getAllCorporation`)
      .then((res: any) => {
        const {
          data: { data },
        } = res
        const object = data.reduce((obj: any, item: any) => Object.assign(obj, { [item.slug]: item.name }), {})
        setCorporations({ _all: 'Todos', ...object })
      })
      .catch((err: any) => console.log('err', err))
  }, [])

  const openCorporationFilterSelector = React.useCallback(async () => {
    const { value: filterCorporationId } = await Swal.fire({
      title: 'Filtro por Corporação',
      input: 'select',
      inputOptions: corporations,
      inputPlaceholder: 'Selecione...',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Filtrar',
    })
    if (filterCorporationId) {
      const url = new URL(window.location.href)
      if (filterCorporationId === '_all') url.searchParams.delete('mainCorporationId')
      else url.searchParams.set('mainCorporationId', filterCorporationId)
      window.location.assign(url.href)
    }
  }, [corporations])
  return (
    <div className="page-title mt-4">
      <div className="row justify-content-between align-items-center">
        <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
          <div className="d-inline-block">
            <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">{title}</h5>
          </div>

          <div className="align-items-center ml-4 d-inline-flex">
            <span className="h4 text-info text-uppercase mb-0 mr-2">{noteStats}</span>
            <span className="text-sm opacity-7 text-white">{noteDescription}</span>
          </div>

          {mainCorporationId && <p className="text-sm text-info d-inline-block ml-4 mb-0">{mainCorporationId.toUpperCase()}</p>}
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
          { <div className="actions actions-dark d-inline-block">
          {(isDebug === false) ?
          <><button onClick={() => {
                const countData = (localStorage.getItem("load") ? localStorage.getItem("load") : 0)
                let myCount = Number(countData)
                myCount += 5
                console.log("REFRSH CLICK", myCount)

                localStorage.setItem("load", myCount.toString())
                setIsRefresh(true)
                //  setTimeout(() => {
                //   setIsRefresh(false)
                //  }, 10000);
              } } className={`action-item ml-md-4 ${currentQueue === 'BOTH' ? 'active' : ''}`} title='Load 5 more'>
                Load ({(localStorage.getItem("load") ? localStorage.getItem("load") : 0)})
              </button><button onClick={() => {
                setPatientStatusData("ONLINE")
                setIsRefresh(true)
                //  setTimeout(() => {
                //   setIsRefresh(false)
                //  }, 10000);
              } } className={`action-item ml-md-4 ${currentQueue === 'ONLINE' ? 'active' : ''}`}>
                  ONLINE
                </button><button onClick={() => {
                  setPatientStatusData("OFFLINE")
                  setIsRefresh(true)
                  //  setTimeout(() => {
                  //   setIsRefresh(false)
                  //  }, 10000);
                } } className={`action-item ml-md-4 ${currentQueue === 'OFFLINE' ? 'active' : ''}`}>
                  OFFLINE
                </button><button onClick={() => {
                  setPatientStatusData("BOTH")
                  setIsRefresh(true)
                  //  setTimeout(() => {
                  //   setIsRefresh(false)
                  //  }, 10000);
                } } className={`action-item ml-md-4 ${currentQueue === 'BOTH' ? 'active' : ''}`}>
                  BOTH
                </button></>

          : <div></div>}






            </div>}
          {enableCorpsFilter && (adminFlag || devFlag) && (
            <a className="btn btn-sm btn-white btn-icon-only rounded-circle ml-4" onClick={openCorporationFilterSelector}>
              <span className="btn-inner--icon">
                <i className="fas fa-filter"></i>
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  )
})
