/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/img-redundant-alt */
import React from 'react'
import { useDebounce } from '@umijs/hooks'
import { concat, map, sortBy, reverse } from 'lodash'
import moment from 'moment'

import { patients, staffs, liveSupport, liveDebug } from '../../clients/iron-fit'
import './LiveDebugPage.styles.scss'

import { PageTitle } from '../../components'

const START_TIME_OFFSET = 60 * 60 * 1000
const SUPPORT_END_TIME_OFFSET = 60 * 60 * 1000
const END_TIME_OFFSET = 24 * 60 * 60 * 1000
const EVENTS_DEBOUNCE_TIME = 200

const queryParams = new URLSearchParams(window.location.search)
const params = {
  supportId: queryParams.get('supportId') || undefined,
  patientId: queryParams.get('patientId') || undefined,
  staffId: queryParams.get('staffId') || undefined,
}

export const LiveDebugPage: React.FC = () => {
  const supportId = params.supportId
  const [support, supportLoading] = liveSupport.useLiveSupport(supportId)

  const patientId = support?.patientId || params.patientId
  const [patient] = patients.usePatient(patientId)
  const staffId = support?.staffId || params.staffId
  const [staff] = staffs.useStaff(staffId)

  const titleDescription = params.supportId ? 'Support' : params.patientId ? 'Patient Only' : 'Staff Only'

  const patientName = patient?.name
  const staffName = staff?.name
  const titleName = React.useMemo(() => {
    const patientFirstName = patientName ? patientName.split(' ')[0] : undefined
    const staffFirstName = staffName ? staffName.split(' ')[0] : undefined
    if (patientFirstName && staffFirstName) return `${patientFirstName} - ${staffFirstName}`
    return patientFirstName || staffFirstName || undefined
  }, [patientName, staffName])

  const [startTime, setStartTime] = React.useState<number | undefined>()
  const [endTime, setEndTime] = React.useState<number | undefined>()

  const supportStartTime = support?.startTime
  const supportPatientReviewTime = support?.patientReviewTime
  const supportStaffReviewTime = support?.staffReviewTime
  React.useEffect(() => {
    if (supportLoading) return
    if (supportStartTime) setStartTime((v) => v || supportStartTime - START_TIME_OFFSET)
    else setStartTime((v) => v || Date.now() - START_TIME_OFFSET)
  }, [supportStartTime, supportLoading, setStartTime])
  React.useMemo(() => {
    if (supportLoading) return
    setEndTime((v) => {
      if (v) return v
      if (supportPatientReviewTime && supportStaffReviewTime) {
        const supportEndTime = supportPatientReviewTime > supportStaffReviewTime ? supportPatientReviewTime : supportStaffReviewTime
        return supportEndTime + SUPPORT_END_TIME_OFFSET
      }
      return Date.now() + END_TIME_OFFSET
    })
  }, [supportPatientReviewTime, supportStaffReviewTime, supportLoading, setEndTime])

  const [patientEvents] = liveDebug.usePatientEvents(startTime && endTime && patientId ? patientId : undefined, { startTime, endTime })
  const [staffEvents] = liveDebug.useStaffEvents(startTime && endTime && staffId ? staffId : undefined, { startTime, endTime })
  const [supportEvents] = liveDebug.useSupportEvents(startTime && endTime && supportId ? supportId : undefined, { startTime, endTime })

  const [patientEventsDebounced, staffEventsDebounced, supportEventsDebounced] = useDebounce(
    [patientEvents, staffEvents, supportEvents],
    EVENTS_DEBOUNCE_TIME
  )

  const events = React.useMemo(() => {
    return reverse(
      sortBy(
        concat(
          map(patientEventsDebounced, (event, eventId) => ({ ...event, eventId, eventOrigin: 'patient' })),
          map(staffEventsDebounced, (event, eventId) => ({ ...event, eventId, eventOrigin: 'staff' })),
          map(supportEventsDebounced, (event, eventId) => ({ ...event, eventId, eventOrigin: 'support' }))
        ),
        'time'
      )
    )
  }, [patientEventsDebounced, staffEventsDebounced, supportEventsDebounced])

  return (
    <div className="page-content live-debug-page">
      <PageTitle title="Iron Live Debug" noteStats={titleName} noteDescription={titleDescription} isDebug={true}/>
      <div className="card overflow-hidden">
        <div className="logs-list">
          {map(events, (event) => {
            const browserName = (event as any).browser?.name
            const browserVersion = (event as any).browser?.version
            const browserOs = (event as any).browser?.os
            const browserInfo = browserName ? `${browserName} v${browserVersion || '?'} os:${browserOs || '?'}` : ''
            const typeClassName = event.type.toLowerCase()
            return (
              <p
                className={`log-event event-type-${typeClassName} text-left`}
                key={event.eventId}
                title={`${event.details}${browserInfo && event.details ? '\n' : ''}${browserInfo}`}
              >
                <span className="date">
                  {moment(event.time).format('YYYY-MM-DD HH:mm:ss.SSS') + ' '}
                  {`[${browserName || 'unknown'}]: `}
                  &nbsp;
                </span>
                {params.supportId && event.eventOrigin === 'patient' && <i className="fas fa-user mr-2"></i>}
                {params.supportId && event.eventOrigin === 'staff' && <i className="fas fa-user-nurse mr-2"></i>}
                <span className="description text-left">{event.description}</span>
              </p>
            )
          })}
        </div>
      </div>
    </div>
  )
}
