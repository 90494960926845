import React from 'react'

import './PulsatingDot.styles.scss'

export const PulsatingDot: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={`ring-container ${className || ''}`}>
      <div className="ringring"></div>
      <div className="circle"></div>
    </div>
  )
}
