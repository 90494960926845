export const PATIENT_EMOJI = '🤒'
export const OPERATOR_EMOJI = '👩‍💼'
export const DOCTOR_EMOJI = '👩‍⚕️'

export const LIVE_EMOJI = '‍️🔴'
export const CONNECTED_EMOJI = '🟢'
export const DISCONNECTED_EMOJI = '❌'
export const DISABLED_EMOJI = '🚫'
export const FINISH_EMOJI = '🏁'
export const LOADING_EMOJI = '🔄'

export const CAM_EMOJI = '📹'
export const MIC_EMOJI = '🎙'

export const STAR_EMOJI = '⭐️'
export const FINISHED_EMOJI = '✅'
export const FAILED_EMOJI = '🆘'
